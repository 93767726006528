.home {
  background-image: url('../../assets/aerial_platform_bw_cropped.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
}

.transparent {
  background-color: #FF4C4C;
  opacity: .9;
  color: white;
  text-align: center;
  margin: auto;
  padding: 12vh 0;
  width: 50%;
}

h1 {
  font-size: calc(45px + 3vw);
  font-weight: 800;
}

h2 {
  font-size: calc(26px + 1.5vw);
}

.title-box h3 {
  background-color: #262626;
  font-size: calc(16px + .5vw);
  margin-top: 10vh;
  padding: 1vh;
}

@media screen and (max-width: 1150px) {
  .transparent {
    width: 80%;
  }

  /* h1 {
    font-size: 9vw;
  }

  h2 {
    font-size: 4vw;
  }

  h3 {
    font-size: 2.5vw;
  } */
}

@media screen and (max-width: 600px) {

  .transparent {
    width: 90%;
    padding: 25px 0;
  }

  /* h1 {
    font-size: 10vw;
  }

  h2 {
    font-size: 5vw;
  } */

  .title-box h3 {
    margin-top: 10px;
  }
}
