.contact {
  display: flex;
  background-color: #262626;
  border-left: 30px solid #FF4C4C;
  min-height: 60vh;
}

.form-container,
.half-page-image,
.half-page-section {
  width: 50%;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form-container {
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

h4 {
  color: white;
  font-size: 35px;
}

input,
textarea {
  font-size: 16px;
  padding: 5px;
  margin-top: 16px;
}

textarea {
  height: 150px;
}

input[type='submit'] {
  border: none;
  width: 50%;
  margin: 0 auto;
  margin-top: 15px;
  font-size: 16px;
  border-radius: 10px;
  padding: 8px;
}

input:focus,
textarea:focus {
  outline: none;
}

input[type='submit']:hover {
  background-color: #FF4C4C;
  color: white;
  cursor: pointer;
}

.error,
.success {
  color: #FF4C4C;
  text-align: center;
  font-size: 16px;
}

.success {
  color: white;
  margin-top: 30px;
  font-size: 18px;
}

.success-container {
  height: 50%;
}

@media screen and (max-width: 800px) {
  .contact {
    display: block;
    border: none;
  }

  .form-container,
  .half-page-section {
    width: auto;
  }

  .half-page-image {
    display: none;
  }
}