#back-to-top-btn {
  border: none;
  background-color: #FF4C4C;
  border-radius: 100%;
  height: 50px;
  width: 50px;
  position: fixed;
  left: 95%;
  top: 90%;
}

@media screen and (max-width: 1000px) {
  #back-to-top-btn {
    left: 90%;
  }
}

@media screen and (max-width: 600px) {
  #back-to-top-btn {
    left: 85%;
  }
}
