/*

black: #262626
red: #FF4C4C

*/

* {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  padding: 0;
}