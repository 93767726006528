/*

black: #262626
red: #FF4C4C
#A9A9A9
*/

.header {
  position: sticky;
  top: 0;
  z-index: 999;
  z-index: 11;
  width: 100%;
}

.contact-header {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2em;
  top: 0;
  left: 0;
  right: 0;
  height: 6em;
}

.contact-unit {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.contact-unit > h3 {
  color: #FF4C4C;
  margin: 0;
  padding: 0;
  font-size: calc(12px + .9vw);
}

.contact-unit > p {
  color: #262626;
  font-size: calc(12px + .7vw);
}

nav {
  display: flex;
  justify-content: flex-end;
  background-color: #262626;
  padding: 25px;
  border-bottom: 5px solid #FF4C4C;
}

.navlink {
  background-color: #262626;
  text-decoration: none;
  font-weight: bold;
  color: white;
  font-size: calc(12px + .5vw);
  margin: 0 25px;
  border: none;
  border-bottom: 5px solid #262626;
}

.navlink:hover {
  border-bottom: 5px solid #FF4C4C;
  cursor: pointer;
}

.hidden {
  display: none;
}

.title {
  display: flex;
  justify-content: center;
}

.title p {
  color: #FF4C4C;
  font-weight: bold;
  font-size: calc(18px + 2vw);
}

.title-logo {
  display: none;
}

@media screen and (max-width: 800px) {
  .title p {
    display: none;
  }

  .title-logo {
    display: block;
    height: 4em;
  }
}

@media screen and (max-width: 600px) {
  .hide-small {
    display: none;
  }

  .contact-header {
    position: relative;
    padding: 0 1em;
    height: 4em;
  }

  .title-logo {
    height: 3em;
  }
}
