/*

black: #262626
red: #FF4C4C

*/

.WhatWeDo {
  text-align: center;
  padding: 80px;
}

.WhatWeDo .logo {
  width: 75px;
}

.WhatWeDo h4 {
  margin: 25px 0;
}

.bundle-container {
  display: flex;
  justify-content: space-around;
  align-items: baseline;
  flex-wrap: wrap;
}

.offer-bundle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20%;
  min-width: 250px;
  padding: 10px;
  flex-basis: 20%;
}

.offer-image {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.offer-bundle h5 {
  color: #FF4C4C;
  font-size: 16px;
  text-transform: uppercase;
  margin: 10px 0;
}

.offer-bundle p {
  color: white;
  font-size: 13px;
  text-align: center;
  width: 200px;
  margin-bottom: 20px;
}

@media screen and (max-width: 1080px) {

  .offer-bundle {
    /* flex-basis: 50%; */
  }

}  

/* @media screen and (max-width: 1040px) {
  /* .WhatWeDo {
    background-color: blue;
  } */

  /* .offer-bundle {
    width: 150
  } */
  
/*} */
